.ant-upload {
  width: 100%;
}

.topic:nth-of-type(1n+1) {
  background-color: rgb(207 250 254);
}

.topic:nth-of-type(2n+1) {
  background-color: rgb(255 237 213);
}

.topic:nth-of-type(3n+1) {
  background-color: rgb(254 226 226);
}