.google-recaptcha > div > div {
  margin: auto;
}

@media screen and (min-width: 1512px) {
  .google-recaptcha {
    transform: scale(1.26, 1.1);
    padding: 1rem 0;
  }
}

@media screen and (min-width: 1270px) and (max-width: 1511px) {
  .google-recaptcha {
    transform: scale(1.1, 1.1);
    padding: 1.5rem 0;
  }
}

.login-outside button svg path {
  @apply fill-main-button-color;
}
