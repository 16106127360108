.reactMultiSelectWrapper .searchWrapper {
  border-color: #d9d9d9;
  transition: all 0.3s;
}
.reactMultiSelectWrapper .searchWrapper .chip {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding-left: 30px;
  margin-right: 16px;
}
.reactMultiSelectWrapper .searchWrapper .chip i {
  position: absolute;
  left: 10px;
  top: 7px;
}

.reactMultiSelectWrapper .searchWrapper:hover {
  border-color: #ed7635;
}
.hasError .searchWrapper {
  border-color: red !important;
  transition: all 0.3s;
}

.hasError .searchWrapper:hover {
  border-color: red !important;
}

.reactMultiSelectWrapper .searchWrapper > input::placeholder {
  color: #bfbfbf;
}

.optionContainer li:hover,
.optionContainer .highlight {
  background: #f3e5db !important;
  color: #32302d !important;
}
.icon_down_dir::before {
  display: none;
}

.ant-input:focus {
  box-shadow: none !important;
  border-color: #ed7635 !important;
}
