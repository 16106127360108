.student-list__modal .unit-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.student-list__modal .unit-passed-icon {
    color: #46a546 !important;
}

.student-list__modal .unit-failed-icon {
    color: #9d261d !important;
}

.student-list__modal .ant-modal-footer {
    justify-content: flex-end;
}

.student-list__modal .unit-action span:hover {
    text-decoration: underline;
}

.student-list__modal .list-unit-item .unit-action {
    display: none;
}

.student-list__modal .list-unit-item:hover .unit-action {
    display: inline-flex;
}

.student-list__modal .ant-btn-default.text-white {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.student-list__modal .unit-info {
    flex: 1 1 auto;
    text-align: right;
    margin-right: 10px;
}

.font-weight-600 {
    font-weight: 600 !important;
}
.font-weight-400 {
    font-weight: 400 !important;
}
.line-height-18 {
    line-height: 18px !important;
}
.line-height-20 {
    line-height: 20px !important;
}
.student-list .ant-progress .ant-progress-outer {
    padding: 0;
    margin: 0;
    display: block;
    height: 100%;
    width: 100%;
}

.student-list .ant-progress .ant-progress-outer .ant-progress-inner {
    height: 100%;
    display: block;
    background-color: #b0bec5;
}

.student-list .ant-progress .ant-progress-text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    margin: 0;
    color: #FFFFFF;
}

.student-list .ant-progress .ant-progress-bg {
    height: 26px !important;
}

.student-list .table-custom__search-result-header {
    visibility: hidden;
}
.custom-row-class{
    background: white;
    height: 50%;
    padding: 60px;
    text-align: center;
    font-weight: 600;
}
.w-100{
    width:100%;
}