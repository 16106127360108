.new_group_btn {
  background: transparent !important;
  color: #32302d !important;
  min-width: auto !important;
  border: 1px solid rgb(217 217 217 / 1) !important;
}

.tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  .tab {
    background-color: transparent;
    color: #32302d;
    min-width: auto;
    border: 1px solid rgb(217 217 217 / 1);
    border-radius: 15px;
    height: 35px;
    padding: 0px 14px;
    cursor: pointer;
  }
}
