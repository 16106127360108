.flex {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  .label {
    margin-left: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    font-style: normal;
    text-align: left !important;
    color: var(--main-font-color);
    @media (max-width: 1279px) {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}
.width {
  width: 100%;
}
.hasError {
  border-color: red !important;
  &:focus {
    border-color: red !important;
  }
  &:hover {
    border-color: red !important;
  }
}
