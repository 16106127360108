.main {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  .img {
    height: 12px;
    width: 12px;
    cursor: pointer;
    margin-left: 0px;
  }
  .img1 {
    margin-right: 20px;
  }

  .label {
    margin-left: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    font-style: normal;
    text-align: left !important;
    color: #32302d;
    @media (max-width: 1279px) {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .selectWrapper {
    // border: 1px solid #d9d9d9;
    .search-wrapper {
      border: 1px solid #d9d9d9;
    }
  }
}
