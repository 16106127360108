.groupList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 50px;
    @media (max-width: 1160px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 540px) {
      grid-template-columns: repeat(1, 1fr);
    }
}
  