@media (max-width: 992px) {   
    .grid-cols-3 {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
}
@media (max-width: 576px) {   
    .grid-cols-3 {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
    .cus-view-item {
        display: block!important;
    }
}