.session {
  font-weight: 500;
  padding: 16px;
  background-color: transparent;
  overflow-wrap: break-word;
  z-index: 2;
  color: rgba(0, 0, 0, 0.85);
  min-width: 170px;
  min-height: 58px;
  width: calc((100% - 60px) / 6) ;
}

.session:first-child {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
  z-index: 3;
  border-top-left-radius: 2px;
  min-width: 60px;
  width: 60px !important;
}

.session-body-container {
  border-radius: 1em;
  background-color: #fff;
  border: 1px solid transparent;

}

.session-body-container:hover {
  border: 1px solid var(--main-button-color);
}

.session-body-p-30 {
  padding-bottom: 30px;
}
.session .custom-text-session{
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    width: -moz-fit-content;
    width: fit-content;
    overflow: hidden;
}