.header-noti-item{
    padding: 16px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}
.header-noti-item span{
    display: block;
    display: -webkit-box;
    max-width: 100%;
    max-height: 43px;
    font-size: 14px;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.header-noti-readed span{
    color: #AEA8A5 !important;
}
.header-noti-item:hover{
    background-color: #FCECD9;
    
}
.badge-noti sup{
    color: #ED7635
}
.custom__w-135 {
    width: 135px;
}

