.box-items {
  align-items: center !important;
}

.mr-40 {
  margin-right: 40px !important;
}
.min-w-200 {
  min-width: 200px !important;
}
.min-w-80 {
  min-width: 80px !important;
}
.m-r-12 {
  margin-right: 12px !important;
}
.menu-folder {
  left: 50%;
  bottom: 100px;
}
.custom-modal-download {
  width: 100vh;
}
.custom-modal-download .custom-upload .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 50vh;
}
.m-t-10 {
  margin-top: 10px !important;
}
.w-336 {
  width: 336px;
}

.color-Earth-5 {
  color: #aea8a5 !important;
}

.point {
  cursor: pointer !important;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-popup.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-submenu-placement-rightTop {
  min-width: 150px;
}

.p-t-b-0 .ant-row {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.m-b-0 {
  margin-bottom: 0px !important;
}

.ant-select-selector {
  height: 100% !important;
}

.customRequired {
  color: #dc3545;
}

.custom-flex-filter {
  display: flex;
  @media (max-width: 475px) {
    flex-wrap: wrap !important;
    gap: 1rem !important;
    align-items: center;
  }
}

@media (max-width: 712px) {
  .sm\:justify-center {
    justify-content: center !important;
  }
}

.unset-min-width {
  min-width: unset;
}

.custom-select-share .ant-select-selector {
  padding: 8px 16px 8px 5px !important;
}
.h-140 {
  height: 140px !important;
}

.ant-input-disabled {
  background: #e9e6e5 !important;
}

.custom-area {
  border-radius: 12px;
}
.h-140 {
  height: 140px !important;
}
/* .custom-hidden-text{
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

} */
.custom-search-icon .mt-4 {
  margin-top: 0px !important;
  padding: 10px 5px 6px 0px;
}
.custom-search-icon .ant-row {
  margin-bottom: 4px;
}
