@media (max-width: 1100px) {
  .cus-btn__update-session {
    display: block !important;
  }
  .cus-btn__update-session button {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .ant-table-wrapper {
    overflow-x: scroll;
    max-width: 100%;
  }
  .cus-table__list-unit .ant-form-horizontal {
    overflow-x: scroll;
  }
  .cus-table__list-unit .scroll_x {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 1279px) {
  .custom-create-w-question > button {
    width: calc(33.33% - 0.5rem) !important;
    min-width: fit-content !important;
  }
  .custom-create-w-question > a {
    width: 100% !important;
  }
}
.ant-table-wrapper {
  overflow-x: auto;
}
