.background-transparent:not(.ant-select-customize-input) .ant-select-selector {
    @apply !bg-transparent !border border-solid border-[#cccccc];
}

.background-transparent > .ant-select-selector:focus,
.ant-select-selector:hover {
  @apply !border border-solid !border-main-button-color;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  @apply !border-main-button-color;
}
