/*switch styles*/
.btn-hour-mode-switch > label.btn-hour-mode-switch-inner{
  width: 100px;
  height: 36px;
  background: rgba(118, 118, 128, 0.12);
  border-radius: 9px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.btn-hour-mode-switch > label.btn-hour-mode-switch-inner:before{
  content: attr(data-on);
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  top: -1px;
  right: 15px;
}

.btn-hour-mode-switch > label.btn-hour-mode-switch-inner:after{
  content: attr(data-off);
  width: 51px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border-radius: 9px;
  position: absolute;
  left: 2px;
  top: 2px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 4px 1px rgba(0, 0, 0, 0.04);
  line-height: 32px;
}

.btn-hour-mode-switch input[type="checkbox"]{
  width: 51px;
  height: 32px;
  opacity: 0;
  top: 0;
  z-index: 1;
}

.btn-hour-mode-switch input[type="checkbox"]:checked + label.btn-hour-mode-switch-inner{
  background: rgba(118, 118, 128, 0.12);
  color: #32302D;
}

.btn-hour-mode-switch input[type="checkbox"]:checked + label.btn-hour-mode-switch-inner:after{
  content: attr(data-on);
  left: 47px;
  background: rgba(255, 255, 255, 1);
}

.btn-hour-mode-switch input[type="checkbox"]:checked + label.btn-hour-mode-switch-inner:before{
  content: attr(data-off);
  right: auto;
  left: 15px;
}
