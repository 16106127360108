/* Login */
.word-custom > .ant-form-item-required {
  text-overflow: ellipsis;
}
.group-collapse,
.card-item-module {
  position: relative;
}
.responsive__form-module {
  position: relative;
}

.button-apply-to-all {
  text-align: right;
  position: absolute;
  right: 32px;
  top: 12px;
  margin: 0;
  z-index: 1;
}

.button-apply-to-all p {
  margin-right: 8px;
  display: inline;
}
.button-apply-to-all .btn-apply-all {
  border: none;
  background-color: transparent;
}
.button-apply-to-all .btn-apply-all:focus {
  outline: none;
}

@media (max-width: 575px) {
  .isHide {
    display: none !important;
  }
  .padding_login_detail {
    padding: 0.5rem !important;
  }
  .custom-width {
    width: 100% !important;
  }
  .custom-width .ant-upload {
    width: 100% !important;
  }
  .cus_box_Shadow {
    box-shadow: none !important;
  }
  .display_center {
    display: flex;
    justify-content: center;
  }
  .custom-font {
    font-size: 12px !important;
  }
  /* css for button calendar */
  .custom-width-col-limit {
    width: 100% !important;
  }
  .custom-width-col-search {
    width: 100% !important;
  }
  .custom-width-col-button-search {
    width: 47.5% !important;
  }
  .custom__w-135 {
    width: 100% !important;
  }
  .custom-font-header {
    font-size: 1.25rem !important;
  }
  .custom-padding {
    padding: 0.75rem !important;
  }
  .custom-margin-right {
    margin-left: 35px;
  }
  .custom-width-col-search-accent-control {
    width: 100%;
  }
  .custom-width-col-button-accent-control {
    width: 48.5%;
  }
  .min-w-270 {
    min-width: 270px !important;
  }
  .custom-botton-content-management {
    width: 48.5% !important;
  }
  .custom-margin-content-management {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
  .custom-margin-t-content-management {
    margin-top: 0.5rem;
  }
  .custom-width-content-management {
    width: 100% !important;
  }
  .custom-font-header-content-management {
    font-size: 1rem !important;
  }
  .custom-font-content-management {
    font-size: 0.75rem !important;
  }
  .p-8 {
    padding: 1rem !important;
  }
  .p-10 {
    padding: 1rem !important;
  }
  .ant-modal-content {
    padding: 0px !important;
  }
  .custom-fill-the-gap > .select {
    margin-left: 20px;
  }

  #droppable {
    width: 100% !important;
  }
  .flex-basic-48 {
    flex-basis: 48.5% !important;
  }
  .w-79 {
    width: 70% !important;
  }
  .w-48 {
    width: 48.5% !important;
  }
  .w-48-class {
    /* width: 48.5% !important; */
  }
  .button-w-32 {
    width: 31.5% !important;
  }
  .button-margin {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .w-70 {
    width: 70% !important;
  }
  .w-30 {
    width: 30% !important;
  }
  .mt-10 {
    margin-top: 10px !important;
  }
  .w-40px {
    width: 40px !important;
  }
  .responsive__form-module .ant-collapse-content-box {
    max-width: 100%;
    overflow-x: scroll;
  }
  .min-width-80 {
    min-width: 80px;
  }
  .min-width-160 {
    min-width: 160px;
  }
  .min-width-200 {
    min-width: 200px;
  }
  .flex-start {
    display: flex;
    justify-content: flex-start;
  }
  .word-break {
    word-break: break-all;
  }
  .f-justify-center {
    justify-content: center !important;
  }
  .w-full #basic_ {
    display: none;
  }
  .ant-picker-datetime-panel {
    display: block;
  }
  .custom-max-width {
    max-width: 100% !important;
  }
  .ant-table-cell-fix-left {
    min-width: 120px;
  }
  .custom-width-table-color {
    width: 100%;
  }
  .ant-picker-dropdown-placement-bottomRight,
  .ant-picker-dropdown-placement-topLeft {
    left: 0px !important;
    position: fixed;
    top: 0px !important;
  }
  .custom-caretory {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
  /* .ant-picker-dropdown-placement-topLeft {
    top: 950px !important;
  } */
}
@media (min-width: 640px) and (max-width: 987px) {
  .custom-padding-annoncement {
    padding-left: 3rem;
  }
  .isHide {
    display: none !important;
  }
  .display-center {
    display: flex !important;
    align-items: center;
    justify-content: center !important;
  }
  .mr-0 {
    margin-right: 0px !important;
  }
  .custom-width {
    width: 100% !important;
  }
  .display-none {
    display: block !important;
  }
  .margin-left-0 {
    margin-left: 0px !important;
  }
  .custom-mt {
    margin-top: 10px;
  }
  .w-40px {
    width: 40px !important;
  }
  .w-64-custom {
    width: 8.5rem !important;
  }
  .bg-transparent .preview-theme {
    max-width: 130px !important;
    min-width: 80px !important;
  }
  .w-48 {
    width: 48.5% !important;
  }
  .min-w-270 {
    min-width: 270px !important;
  }
  .w-90 {
    width: 94% !important;
  }
  .ant-dropdown-placement-bottomRight {
    position: fixed;
    top: 20% !important;
    left: 20% !important;
  }
  .ant-layout-sider-children {
    height: auto;
  }
  .custom-margin-content-management {
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
  }
  .custom-margin-t-content-management {
    margin-top: 0.75rem;
  }
  .custom-width-content-management {
    width: 100% !important;
  }
  .custom-font-header-content-management {
    font-size: 1rem !important;
  }

  .custom-width-col-search-accent-control {
    width: 70%;
  }
  .custom-width-col-button-accent-control {
    width: 13%;
  }
  .bg-transparent .preview-theme {
    max-width: 130px !important;
    min-width: 80px !important;
  }
  .custom-height {
    height: auto !important;
  }
  .custom-botton-content-management {
    width: 49% !important;
  }
  .custom-padding-left {
    padding-left: 3rem;
  }
  .custom-caretory {
    display: flex;
    margin-top: 0.5rem;
    gap: 0.5rem;
  }
  .custom-padding-right {
    padding-right: 0.75rem;
  }
  .custom-noti {
    width: 250px;
    overflow-y: scroll;
  }
}
@media (min-width: 1024px) {
  .custom-width-col-search {
    width: 50% !important;
  }
  .isHide {
    display: flex;
  }
  .custom-width-col-search-accent-control {
    width: 70% !important;
  }
  .custom-font {
    font-size: 14px !important;
  }
  .custom-font-header {
    font-size: 1.75rem !important;
  }
  .w-53 {
    width: 53% !important;
  }
  .w-49 {
    width: 49% !important;
  }
  .w-79 {
    width: 79% !important;
  }
  .w-32 {
    width: 31.2% !important;
  }
  .cus-mt-1_25rem {
    padding-top: 1.25rem !important;
  }
  .custom-caretory {
    gap: 1rem;
  }
}
@media (min-width: 1280px) {
  .w-32 {
    width: 32% !important;
  }
}
@media (min-width: 1536px) {
  .w-32 {
    width: 32.2% !important;
  }
}
.cus-width-management {
  height: 44px;
}
@media (max-width: 575px) {
  .isHide {
    display: none;
  }
  .padding_login_detail {
    padding: 0.5rem !important;
  }
  .custom-width {
    width: 100% !important;
  }
  .custom-width .ant-upload {
    width: 100% !important;
  }
  .cus_box_Shadow {
    box-shadow: none !important;
  }
  .display_center {
    display: flex;
    justify-content: center;
  }
  .custom-font {
    font-size: 12px !important;
  }
  /* css for button calendar */
  .custom-width-col-limit {
    width: 100% !important;
  }
  .custom-width-col-search {
    width: 100% !important;
  }
  .custom-width-col-button-search {
    width: 47.5% !important;
  }
  .custom__w-135 {
    width: 100% !important;
  }
  .custom-font-header {
    font-size: 1.25rem !important;
  }
  .custom-padding {
    padding: 0.75rem !important;
  }
  .custom-margin-right {
    margin-left: 35px;
  }
  .custom-width-col-search-accent-control {
    width: 100%;
  }
  .custom-width-col-button-accent-control {
    width: 48.5%;
  }
  .min-w-270 {
    min-width: 270px !important;
  }
  .custom-botton-content-management {
    width: 48.5% !important;
  }
  .custom-margin-content-management {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
  .custom-margin-t-content-management {
    margin-top: 0.5rem;
  }
  .custom-width-content-management {
    width: 100% !important;
  }
  .custom-font-header-content-management {
    font-size: 1rem !important;
  }
  .custom-font-content-management {
    font-size: 0.75rem !important;
  }
  .p-8 {
    padding: 1rem !important;
  }
  .p-10 {
    padding: 1rem !important;
  }
  .ant-modal-content {
    padding: 0px !important;
  }
  .custom-fill-the-gap > .select {
    margin-left: 20px;
  }

  #droppable {
    width: 100% !important;
  }
  .flex-basic-48 {
    flex-basis: 48.5% !important;
  }
  .w-79 {
    width: 70% !important;
  }
  .w-48 {
    width: 48.5% !important;
  }
  .button-w-32 {
    width: 31.5% !important;
  }
  .button-margin {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .w-70 {
    width: 70% !important;
  }
  .w-30 {
    width: 30% !important;
  }
  .mt-10 {
    margin-top: 10px !important;
  }
  .w-40px {
    width: 40px !important;
  }

  .flex-start {
    display: flex;
    justify-content: flex-start;
  }
  .word-break {
    word-break: break-all;
  }
  .f-justify-center {
    justify-content: center !important;
  }
  .w-full #basic_ {
    display: none;
  }
  .ant-picker-datetime-panel {
    display: block;
  }
  .ant-picker-date-panel,
  .ant-picker-year-panel {
    width: 100%;
    padding: 0.5rem;
  }
  .custom-max-width {
    max-width: 100% !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .ant-picker-date-panel {
    width: 100%;
    padding: 0;
  }
}
@media (min-width: 576px) {
  .w-50-remind {
    width: 50% !important;
  }
}
@media (min-width: 1024px) {
  .custom-font-header__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .custom-font-header__box .custom-font-header__title {
    font-size: 1.25rem !important;
  }
  .custom-width-col-search {
    width: 50% !important;
  }
  .isHide {
    display: flex;
  }
  .custom-width-col-search-accent-control {
    width: 70% !important;
  }
  .custom-font {
    font-size: 14px !important;
  }
  .custom-font-header {
    font-size: 1.75rem !important;
  }
  .w-53 {
    width: 53% !important;
  }
  .w-49 {
    width: 49% !important;
  }
  .w-79 {
    width: 79% !important;
  }
  .w-32 {
    width: 31.2% !important;
  }
  .cus-mt-1_25rem {
    padding-top: 1.25rem !important;
  }
  .w-50-remind {
    width: 0% !important;
  }
}
@media (min-width: 1280px) {
  .w-32 {
    width: 32% !important;
  }
}
@media (min-width: 1536px) {
  .w-32 {
    width: 32.2% !important;
  }
}
.cus-width-management {
  height: 44px;
}
@media (max-width: 1023px) {
  .responsive__form-module .ant-collapse-content-box {
    max-width: 100%;
    overflow-x: scroll;
  }
  .min-width-80 {
    min-width: 80px;
  }
  .min-width-160 {
    min-width: 160px;
  }
  .min-width-200 {
    min-width: 200px;
  }
  .custom-font-content-management {
    font-size: 0.75rem !important;
  }
}
@media (max-width: 1300px) {
  .cus-width-management {
    width: auto !important;
    height: 44px;
    margin-top: 20px;
  }
  .cus-margin {
    margin-right: 8px;
    margin-left: 8px;
  }
}
@media (max-width: 576px) {
  .cus-width-management {
    width: 45% !important;
    height: 44px;
    margin-top: 20px;
  }
  .display-none {
    display: block !important;
  }
  .display-unset {
    display: unset;
  }
  .min-width-260 {
    min-width: 260px !important;
  }

  .item-half-width {
    width: 100%;
  }
  .custom-gap {
    gap: 4rem;
  }
  .custom-pagination-sp .ant-pagination-item {
    margin: 0 !important;
  }
  .footer-course-sp {
    justify-content: center !important;
    flex-direction: column !important;
  }
  .custom-mt {
    margin-top: 10px;
  }
  .w-64-custom {
    width: 10rem !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .space-between {
    display: flex;
    justify-content: space-between;
  }
  .w-90 {
    width: 90% !important;
  }
  .w-80-custom {
    width: 80% !important;
  }
  .custom-left {
    left: 0% !important;
    top: -60% !important;
    height: fit-content;
  }
}
@media (max-width: 576px) {
  .ant-picker-panels {
    flex-direction: column;
  }
  .min-width-select {
    min-width: fit-content !important;
  }
}
@media (max-width: 1023px) {
  .session-state {
    width: calc(50% - 0.375rem) !important;
  }
}
/*Kiet*/
/* 
    @media (max-width: 575px) {
    /*Course*/
/* 
    .filter-item-sp {
        justify-content: space-between;
        width: 100%;
    }
    .custom-pagination-sp .ant-pagination-item {
        margin: 0 !important;
    }
    .footer-course-sp {
        justify-content: center !important;
        flex-direction: column !important;
    }
    .filter-sp form {
        justify-content: space-between;
    }

    /*Admin*/
/* .button-sp {
        min-width: 8rem !important;
        height: 2.75rem !important;
    }
    .class-btn-sp {
        width: 100% !important;
    }
    } */

.css-dao3x5-ValueContainer,
.css-zpzego {
  white-space: nowrap;
  width: 50px !important;
  overflow: hidden;
  text-overflow: clip !important;
}
