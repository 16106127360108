.noti-content{
    display: block;
    display: -webkit-box;
    max-width: 100%;
    max-height: 43px;
    font-size: 14px;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

