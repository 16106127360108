@media (max-width: 992px) {   
    .custom-configrure {
        display: grid!important;
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
}
@media (max-width: 576px) {   
    .custom-configrure {
        display: grid!important;
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
}