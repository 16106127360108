.cardWrapper {
  min-height: 272px;
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;

  .icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  h1 {
    text-align: center;
    max-width: 100%;
    width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
