.cus__width__carditems {
  width: calc(33.33% - 0.67rem) !important;
}

@media (max-width: 767px) {
  .cus__width__carditems {
    width: 80% !important;
  }
}
.self-center {
  align-self: center !important;
}
.box-privew {
  min-width: 230px;
  min-height: 162px;
}
.justtify-center {
  justify-content: center !important;
}
.box-privew  img {
  max-width: 100%;
  max-height: 162px;
}
