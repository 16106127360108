.ql-toolbar.ql-snow {
  border-bottom: none;
  background-color: #fcfcfc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ql-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
